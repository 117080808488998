export default function authHeader(contentType?: string) {
    const storedUser = localStorage.getItem('user');
    let user = JSON.parse(storedUser ? storedUser : "{}");

    if (user && user.accessToken) {
       
        if (contentType != null && typeof contentType != "undefined")
            return { Authorization: 'Bearer ' + user.accessToken, "Content-Type": contentType };
        else
            return { Authorization: 'Bearer ' + user.accessToken };        
    } else {
        if (contentType != null && typeof contentType != "undefined") {
            return { "Content-Type": contentType };
        }
        else {
          
            return {};
        }
    }
}