export class Utils {
    static downloadFile(allegato: any) {
        var sampleArr = this.base64ToArrayBuffer(allegato.stream);
        this.saveByteArray(allegato.nome, sampleArr, allegato.type);
    }

    static base64ToArrayBuffer(base64: any) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    static saveByteArray(reportName: any, byte: any, type: string) {
        var blob = new Blob([byte], { type: type });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.target = "_blank";
        var fileName = reportName;
        link.download = fileName;
        link.click();
    };

    static createLinkFile(allegato: any) {
        var sampleArr = this.base64ToArrayBuffer(allegato.stream);
        var blob = new Blob([sampleArr], { type: allegato.type });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        return link.href;
    };

    static playFile(allegato: any) {
        allegato.audio = new Audio(Utils.createLinkFile(allegato));
        allegato.isPlaying = true;
        allegato.iconPlay = "pause";
        allegato.audio.play();
    }

    static pauseFile(allegato: any) {
        allegato.isPlaying = false;
        allegato.iconPlay = "play";
        allegato.audio.pause();
    }

    static groupBy(array: any, key: any) {
        // Return the end result
        return array.reduce((result: any, currentValue: any) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result;
        }, {}); // empty object is the initial value for result object
    };
    static pad(s: any) { return (s < 10) ? '0' + s : s; }
    static convertDate(inputFormat: any) {
        var d = new Date(inputFormat)
        return [this.pad(d.getDate()), this.pad(d.getMonth() + 1), d.getFullYear()].join('/')
    }

    static createGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

}