export default class MultipleMultiselectItem {
    nome: string;
    id: number;
    ordinamento: number = 0;
    constructor(_nome: string, _id: number, _ordinamento: number = 0) {
        this.nome = _nome;
        this.id = _id;
        this.ordinamento = _ordinamento;
    }
}

